





























import { Component, Mixins } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../abstract/ui'
import { NavigationCTABoxModule } from '../NavigationCTABox.contracts'
import { ProductData, Variant as ProductVariant } from '../../../contexts'
import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<NavigationCTABoxModuleUi>({
  name: 'NavigationCTABoxModuleUi'
})
export class NavigationCTABoxModuleUi extends Mixins<AbstractModuleUi<NavigationCTABoxModule>>(
  AbstractModuleUi) {
  public get product (): ProductData | null {
    return this.data.content.product
  }

  public get activeVariant (): ProductVariant<string> | null {
    if (!this.product) {
      return null
    }

    // First variant
    return Object.values(this.product.variants)[0]
  }

  public get productName () {
    return this.activeVariant?.name
  }

  /**
   * Product's image.
   */
  public get productImage (): ImageProps {
    if (!this.activeVariant) {
      return {
        src: '',
        alt: ''
      }
    }

    if (Array.isArray(this.activeVariant.images) && this.activeVariant.images.length > 0) {
      return toImageProps(this.activeVariant.images[0])
    }

    return {
      src: '',
      alt: ''
    }
  }

  /**
   * Determines whether product has discount.
   */
  public get hasDiscount (): boolean {
    if (!this.activeVariant) {
      return false
    }

    return this.activeVariant.price.regularPrice > this.activeVariant.price.finalPrice
  }
}

export default NavigationCTABoxModuleUi
