import { render, staticRenderFns } from "./NavigationCTABox.ui.vue?vue&type=template&id=3273dabc&"
import script from "./NavigationCTABox.ui.vue?vue&type=script&lang=ts&"
export * from "./NavigationCTABox.ui.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports